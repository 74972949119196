
.languages {
	border-radius: 200px;
	background: $color-brand-darkest none no-repeat 13px 50% / 22px auto;
	color: white;
	box-shadow: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font-size: rem(12px);
	font-weight: 600;
	min-width: 140px;
	height: 50px;
	padding: 10px 10px 10px 46px;
	border: 0;

	@include min(1040px) {
		border-radius: 200px 0 0 200px;
	}

	&.language-cs {
		background-image: url(/assets/images/flag-cs.svg);
	}
}
