@use "sass:math";

/*
* BASE DEPENDENCIES
*/

@import "abstracts/functions", "abstracts/mixins", "abstracts/vars",
	"abstracts/animations", "abstracts/templates";

/*
* COMMON BLOCKS
*/

@import "layout/header", "layout/footer";

/*
* DESIGN COMPONENTS
*/
// TODO: Fix default styles
@import
	//"components/search-form",
	"components/contact-form",
	//"components/search",
	//"components/hamburgers/hamburgers",
	//"components/table",
	"components/button", "components/fullscreen-search",
	"components/main-nav", "components/swipeview", "components/pagination",
	"components/gallery", "components/jobs", "components/languages";

.fields {
	padding: 100px 0;

	&__list {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 30px;

		@include min(800px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@include min(1400px) {
			grid-template-columns: repeat(6, 1fr);
		}

		.page & {
			grid-gap: 15px;
			gap: 15px;
		}
	}

	&__item {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		//max-width: rem(240px);
		margin: 0;

		.page & {
			background-color: #dfe9f5;
			padding: 60px 20px 40px;
		}
	}

	&__link {
		font-size: rem(21px);
		font-family: $font-headings;
		font-weight: bold;
		line-height: math.div(28, 21);
		display: flex;
		flex-direction: column;
		align-items: center;

		@include link(&) {
			color: $color-brand;
			text-decoration: none;
		}

		@include link-over(&) {
			color: $color-brand-darkest;

			svg .accent {
				fill: $color-secondary;
			}
		}
	}

	&__icon {
		margin: 0 0 10px;

		svg {
			width: 150px;
			max-width: 100%;
			height: auto;

			@include min(1600px) {
				width: 193px;
			}
		}
	}
}

.additional {
	background-color: $color-brand;
	color: white;
	padding: 100px 0;

	&__content {
		display: grid;
		grid-gap: 40px;
		gap: 40px;

		@include min(800px) {
			grid-template-columns: 3fr 4fr 5fr auto;
		}

		@include min(1200px) {
			grid-gap: 100px;
			gap: 100px;
		}
	}

	.pages {
		&__heading {
			font-size: rem(26px);
		}

		&__list {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}

		&__item {
			margin: 0 0 12px;
		}

		&__link {
			@include link(&) {
				color: inherit;
				text-decoration: underline;
			}

			@include link-over(&) {
				text-decoration: none;
			}
		}
	}
}

.address {
	&__logo {
		margin: 0 0 20px;
	}

	&__group {
		margin-bottom: 18px;
		padding-left: 54px;
		background: transparent none no-repeat 0 50%;
		line-height: math.div(44, 16);

		&--location {
			background-image: url(/assets/images/icon-location.svg);
		}
		&--phone {
			background-image: url(/assets/images/icon-phone.svg);
		}
		&--email {
			background-image: url(/assets/images/icon-mail.svg);
		}
	}

	&__line {
		margin: 0;
		padding: 4px 0;
		line-height: normal;
	}

	&__link {
		@include link(&) {
			font-weight: bold;
			font-size: rem(14px);
			text-transform: uppercase;
			text-decoration: underline;
		}

		@include link-over(&) {
			text-decoration: none;
		}
	}
}
