
.contact-form {
	display: grid;
	grid-gap: 4px;
	gap: 4px;
	position: relative;

	&__heading {
		font-size: rem(26px);
	}

	&::after {
		content: "";
		width: 75px;
		height: 75px;
		background: transparent url(/assets/images/svg-loaders/form-loader.svg) no-repeat 0 0 / 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 10;
		transform: translate(-50%, -50%);
		display: none;
	}

	&.is-loading {
		opacity: 0.5;
		pointer-events: none !important;

		&::after {
			display: block;
		}

		* {
			pointer-events: none !important;
		}
	}

	&__section {

	}

	&__submit-section {
		margin-top: 12px;
	}

	&__label {
		margin: 0 0 .25em;
		padding: 0;
		display: inline-block;
	}

	&__input {
		min-height: 50px;
	}

	&__textarea {
		min-height: 120px;
	}

	&__input,
	&__textarea {
		transition: border-color .11s ease, box-shadow .21s ease;
		box-shadow: none;
		width: 100%;
		font-size: 1em;
		padding-left: 16px;
		padding-right: 16px;
		border-radius: 0;
		border: 0;
		background-color: darken($color-brand, 10%);
		color: #fff;
		background-image: none;
		-webkit-appearance: none;
		-moz-appearance: none;

		&:focus {
			outline: 2px $color-secondary solid;
		}

		&--has-error {
			border-color: rgb(161, 28, 28) !important;
		}
	}

	&__input {
		padding: .5em .85em;
	}

	&__textarea {
		resize: vertical;
		min-height: 12rem;
		padding: .85em;
	}

	&__fail-message,
	&__success-message,
	&__error-message {
		font-size: rem(14px);
		margin: .75em 0 0;
	}

	&__success-message {
		color: rgb(64, 160, 45)
	}

	&__fail-message,
	&__error-message {
		color: rgb(161, 28, 28);
	}

	&__button {
		font-weight: bold;
		font-size: rem(14px);
		padding: 0;
		background: transparent none;
		box-shadow: none;
		text-transform: uppercase;
		text-decoration: underline;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
		}
	}
}
