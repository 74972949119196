$fs-background: $color-brand;
$fs-text: white;

.fullscreen-search {
   transition: background-color 0.1s ease-out;
   position: fixed;
   top: 0;
   left: -999em;
   z-index: 19000;
   width: 0.1px;
   height: 0.1px;
   overflow: hidden;
   //background: transparent;
   text-align: center;
   font-family: $font-body;
   background: rgba($fs-background, 0.9);

   &:target,
   &[aria-hidden="false"] {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: auto;
      height: auto;
      backdrop-filter: blur(8px) grayscale(100%);
		display: flex;
		justify-content: center;
		align-items: center;
   }

   &__form {
      font-size: 1rem;
      width: 90%;
      max-width: 1300px;
      margin: 0 auto;
      padding: 0;
      text-align: left;
      color: #ffffff;
      line-height: 1.4;
		transform: translateY(-20vh);
   }

	&__main {
      display: grid;
		grid-template-columns: 1fr 50px;
		grid-gap: 20px;
		gap: 20px;
		align-items: center;

		@include min(800px) {
			grid-template-columns: 1fr 70px;
		}
   }

   &__close {
      transition: background-color 0.15s ease-out;
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 50px;
		padding: 0;
      cursor: pointer;
      background: transparent
         encode-svg(
            '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" fill="#{$fs-text}" d="M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z"/></svg>'
         )
         no-repeat
         center / 34px;

		@include min(800px) {
			width: 70px;
      	height: 70px;
			background-size: 40px;
		}

		@include link-over(&) {
			background-color: $fs-text;
			background-image: encode-svg(
				'<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" fill="#{fs-background}" d="M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z"/></svg>'
				);
		}
   }

   &__label {
      font-size: rem(18px);
      display: block;
      margin: 0 0 0.9em 0;
      color: $fs-text;
      text-transform: uppercase;
      font-weight: 600;
   }

   &__button {
      appearance: none;
      border: 0;
      background: transparent encode-svg('<svg width="72" height="72" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"><path d="M7,36L63.5,36L7,36Z" style="fill:none;stroke:#{$fs-text};stroke-width:2px;"/><path d="M36,3L63.5,36L36,69" style="fill:none;stroke:#{$fs-text};stroke-width:2px;"/></svg>') no-repeat center / 100%;
      color: $fs-text;
      width: 100%;
		padding: 0;
      margin: 0;
		height: 50px;
   }

   &__input {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
      font-size: rem(24px);
      width: 100%;
      font-family: $font-body;
      background: transparent none;
      color: $fs-text;
      outline: 0;
      font-weight: normal;
      padding: .5em 0;
      border: {
         width: 0 0 2px 0;
         color: rgba($fs-text, 0.8);
         style: dotted;
      }

      &:focus {
         box-shadow: none !important;
      }

      &::placeholder {
         color: rgba($fs-text, 0.8);
      }

      @include min(600px) {
         font-size: 4vw;
      }

      @include min($max-width) {
      	padding: .15em 0;
      }
   }
}
