/*
* BASE DEPENDENCIES
*/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
@keyframes fade-into-foreground {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes fade-into-background {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(.75);
		opacity: 0;
	}
}

@keyframes pop-into-foreground {
	from {
		transform: scale(.5);
		opacity: 0;
	} to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pop-into-foreground-with-bounce {
	0% {
		transform: scale(.5);
		opacity: 0;
	} 72% {
		transform: scale(1.07);
		opacity: .72;
	} 100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
	} 100% {
		box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
	}
}

@keyframes slide-from-top {
	from {
		transform: translateY(-100%);
	} to {
		transform: translateY(0);
	}
}
*/
/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
/*
* COMMON BLOCKS
*/
.header {
  color: #ffffff;
  position: relative;
  padding-top: 35px;
  padding-bottom: min(180px, 18%);
  background-color: #1d2b82;
  background-repeat: no-repeat, no-repeat;
  background-position: 50% 100%, 50% 0;
  background-size: auto, cover;
  background-image: url("/assets/images/arc.svg"), url("/assets/images/hero.jpg");
  background-image: url("/assets/images/arc.svg"), image-set(url("/assets/images/hero.avif") type("image/avif"), url("/assets/images/hero.jpg") type("image/jpeg"));
}
@media screen and (min-width: 1024px) {
  .header {
    padding-top: 60px;
  }
}
@media screen and (min-width: 1400px) {
  .header {
    padding-top: 90px;
  }
}
body:not([data-view=home]) .header {
  padding-bottom: min(160px, 10%);
}
.header__top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 1040px) {
  .header__top {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.header__logo {
  margin: 0 0 30px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}
.header__logo img {
  width: 300px;
}
@media screen and (min-width: 600px) {
  .header__logo img {
    width: 400px;
  }
}
@media screen and (min-width: 1040px) {
  .header__logo img {
    width: 250px;
  }
}
@media screen and (min-width: 1200px) {
  .header__logo img {
    width: 400px;
  }
}
@media screen and (min-width: 1500px) {
  .header__logo img {
    width: 495px;
  }
}
.header__tools {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  gap: 12px;
}
@media screen and (min-width: 800px) and (max-width: 1039px) {
  .header__tools {
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 1040px) {
  .header__tools {
    display: grid;
    grid-template-columns: initial;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.header__cta {
  max-width: 780px;
  padding-top: 30px;
}
@media screen and (min-width: 1400px) {
  .header__cta {
    padding-top: 90px;
  }
}
.header__cta .cta__heading {
  font-weight: bold;
  font-size: 1.875rem;
  font-family: "Exo 2", Poppins, Futura, Montserrat, sans-serif;
  line-height: normal;
  margin: 0 0 34px;
}
@media screen and (min-width: 800px) {
  .header__cta .cta__heading {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 1200px) {
  .header__cta .cta__heading {
    font-size: 3.125rem;
  }
}
.header__cta .cta__text {
  font-size: 1.125rem;
  line-height: 1.7;
  margin-bottom: 32px;
}
@media screen and (min-width: 1200px) {
  .header__cta .cta__text {
    font-size: 1.25rem;
  }
}
.header__move-button {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}
.header__move-button::before, .header__move-button::after {
  content: "";
  display: inline-block;
}
.header__move-button::before {
  width: 30px;
  height: 52px;
  border-radius: 52px;
  background-color: #d94200;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.41);
}
.header__move-button::after {
  width: 2px;
  height: 7px;
  border-radius: 100px;
  background-color: white;
  position: absolute;
  top: 13px;
  left: 50%;
  margin-left: -1px;
}
.header__move-button, .header__move-button:link, .header__move-button:visited {
  text-decoration: none;
}
.header__move-button:hover, .header__move-button:focus, .header__move-button:active {
  text-decoration: underline;
}
.header__move-button span {
  text-transform: lowercase;
  position: absolute;
  left: 50%;
  top: -18px;
  transform: translate(-50%, -100%);
  font-size: 0.75rem;
  font-weight: 600;
  white-space: nowrap;
}

.search-button {
  border-radius: 200px;
  background: #000839 url(/assets/images/magnifying-glass.svg) no-repeat 15px 50%/22px auto;
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  min-width: 140px;
  height: 50px;
  padding: 10px 10px 10px 46px;
  display: inline-flex;
  align-items: center;
}
@media screen and (min-width: 1040px) {
  .search-button {
    border-radius: 200px 0 0 200px;
  }
}
.search-button, .search-button:link, .search-button:visited {
  color: white;
  text-decoration: none;
}
.search-button:hover, .search-button:focus, .search-button:active {
  text-decoration: underline;
}

.footer {
  text-align: center;
  margin-top: auto;
  padding-top: 52px;
  padding-bottom: 52px;
  font-size: 0.875rem;
  position: relative;
  background-color: #26348b;
  border-top: 1px #303e90 solid;
  color: white;
}
@media screen and (min-width: 800px) {
  .footer__copyright {
    margin: 0;
  }
}
@media screen and (min-width: 800px) {
  .footer__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.footer a,
.footer a:link,
.footer a:visited {
  color: inherit;
  text-decoration: underline;
}
.footer__login-link {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  font-size: 1rem;
  opacity: 0;
}
.footer__login-link, .footer__login-link:link, .footer__login-link:visited {
  color: inherit;
  text-decoration: none;
}
.footer__login-link:hover, .footer__login-link:focus, .footer__login-link:active {
  opacity: 1;
}

/*
* DESIGN COMPONENTS
*/
.contact-form {
  display: grid;
  grid-gap: 4px;
  gap: 4px;
  position: relative;
}
.contact-form__heading {
  font-size: 1.625rem;
}
.contact-form::after {
  content: "";
  width: 75px;
  height: 75px;
  background: transparent url(/assets/images/svg-loaders/form-loader.svg) no-repeat 0 0/100%;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  display: none;
}
.contact-form.is-loading {
  opacity: 0.5;
  pointer-events: none !important;
}
.contact-form.is-loading::after {
  display: block;
}
.contact-form.is-loading * {
  pointer-events: none !important;
}
.contact-form__submit-section {
  margin-top: 12px;
}
.contact-form__label {
  margin: 0 0 0.25em;
  padding: 0;
  display: inline-block;
}
.contact-form__input {
  min-height: 50px;
}
.contact-form__textarea {
  min-height: 120px;
}
.contact-form__input, .contact-form__textarea {
  transition: border-color 0.11s ease, box-shadow 0.21s ease;
  box-shadow: none;
  width: 100%;
  font-size: 1em;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 0;
  border: 0;
  background-color: #1b2563;
  color: #fff;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.contact-form__input:focus, .contact-form__textarea:focus {
  outline: 2px #d94200 solid;
}
.contact-form__input--has-error, .contact-form__textarea--has-error {
  border-color: rgb(161, 28, 28) !important;
}
.contact-form__input {
  padding: 0.5em 0.85em;
}
.contact-form__textarea {
  resize: vertical;
  min-height: 12rem;
  padding: 0.85em;
}
.contact-form__fail-message, .contact-form__success-message, .contact-form__error-message {
  font-size: 0.875rem;
  margin: 0.75em 0 0;
}
.contact-form__success-message {
  color: rgb(64, 160, 45);
}
.contact-form__fail-message, .contact-form__error-message {
  color: rgb(161, 28, 28);
}
.contact-form__button {
  font-weight: bold;
  font-size: 0.875rem;
  padding: 0;
  background: transparent none;
  box-shadow: none;
  text-transform: uppercase;
  text-decoration: underline;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.contact-form__button:hover, .contact-form__button:focus, .contact-form__button:active {
  text-decoration: none;
}

.button {
  border-radius: 300px;
  padding: 15px 20px 16px;
  min-width: 13.75rem;
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0;
  background-color: #d94200;
  color: #fff;
  text-align: center;
  transition: background-color 0.15s ease, box-shadow 0.15s ease;
  position: relative;
  box-shadow: none;
  display: inline-block;
  /* &[disabled] {
  	color: #999999;
  	background-color: #ebebeb;
  	cursor: not-allowed;
  }

  &.-icon {
  	&::before {
  		vertical-align: middle;
  		margin-right: .75em;
  		display: inline-block;
  	}
  }

  &.-primary {
  	background-color: $button-primary-bg;
  	box-shadow: 0 0 0 0 $button-primary-bg;
  	color: $button-primary-fg;

  	&:not([disabled]) {
  		&:hover,
  		&:focus,
  		&:active {
  			box-shadow: 0 5px 20px $button-primary-bg;
  		}
  	}
  } */
}
.button--with-shadow {
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.13);
}
.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active {
  z-index: 3;
  outline: 0;
}
.button, .button:link, .button:visited {
  text-decoration: none;
}
.button:hover, .button:focus, .button:active {
  background-color: #26348b;
}
.button:nth-last-child(n+2), .button:nth-last-child(n+2) ~ .button {
  margin-bottom: 0.5em;
}

.button:not(:first-child):not(.-block) {
  margin-left: 0.5em;
}

.fullscreen-search {
  transition: background-color 0.1s ease-out;
  position: fixed;
  top: 0;
  left: -999em;
  z-index: 19000;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  text-align: center;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  background: rgba(38, 52, 139, 0.9);
}
.fullscreen-search:target, .fullscreen-search[aria-hidden=false] {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: auto;
  height: auto;
  backdrop-filter: blur(8px) grayscale(100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.fullscreen-search__form {
  font-size: 1rem;
  width: 90%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0;
  text-align: left;
  color: #ffffff;
  line-height: 1.4;
  transform: translateY(-20vh);
}
.fullscreen-search__main {
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
@media screen and (min-width: 800px) {
  .fullscreen-search__main {
    grid-template-columns: 1fr 70px;
  }
}
.fullscreen-search__close {
  transition: background-color 0.15s ease-out;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  padding: 0;
  cursor: pointer;
  background: transparent url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='white' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E") no-repeat center/34px;
}
@media screen and (min-width: 800px) {
  .fullscreen-search__close {
    width: 70px;
    height: 70px;
    background-size: 40px;
  }
}
.fullscreen-search__close:hover, .fullscreen-search__close:focus, .fullscreen-search__close:active {
  background-color: white;
  background-image: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='fs-background' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E");
}
.fullscreen-search__label {
  font-size: 1.125rem;
  display: block;
  margin: 0 0 0.9em 0;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}
.fullscreen-search__button {
  appearance: none;
  border: 0;
  background: transparent url("data:image/svg+xml;utf8,%3Csvg width='72' height='72' viewBox='0 0 72 72' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;'%3E%3Cpath d='M7,36L63.5,36L7,36Z' style='fill:none;stroke:white;stroke-width:2px;'/%3E%3Cpath d='M36,3L63.5,36L36,69' style='fill:none;stroke:white;stroke-width:2px;'/%3E%3C/svg%3E") no-repeat center/100%;
  color: white;
  width: 100%;
  padding: 0;
  margin: 0;
  height: 50px;
}
.fullscreen-search__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1.5rem;
  width: 100%;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  background: transparent none;
  color: white;
  outline: 0;
  font-weight: normal;
  padding: 0.5em 0;
  border-width: 0 0 2px 0;
  border-color: rgba(255, 255, 255, 0.8);
  border-style: dotted;
}
.fullscreen-search__input:focus {
  box-shadow: none !important;
}
.fullscreen-search__input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
@media screen and (min-width: 600px) {
  .fullscreen-search__input {
    font-size: 4vw;
  }
}
@media screen and (min-width: 1530px) {
  .fullscreen-search__input {
    padding: 0.15em 0;
  }
}

.main-nav {
  padding: 0;
}
.main-nav__heading {
  margin-top: 0.5em;
}
.main-nav__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  gap: 12px;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
@media screen and (min-width: 800px) {
  .main-nav__list {
    grid-gap: 0;
    gap: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.main-nav__item {
  text-align: center;
  font-weight: 700;
  display: inline-flex;
  font-size: 1rem;
  margin: 0;
}
@media screen and (min-width: 800px) {
  .main-nav__item {
    margin: 0 0 0 12px;
  }
  .main-nav__item:first-child {
    margin-left: 0;
  }
}
.main-nav__link {
  padding: 13px 20px;
  min-width: 10rem;
  background-color: #000839;
  transition: background-color 0.15s ease-out;
  border-radius: 300px;
}
.main-nav__link, .main-nav__link:link, .main-nav__link:visited {
  text-decoration: none;
  color: #fff;
}
.main-nav__link:hover, .main-nav__link:focus, .main-nav__link:active {
  background-color: #d94200;
}
.main-nav__link[aria-current], .main-nav__link[aria-current]:link, .main-nav__link[aria-current]:visited, .main-nav__link[aria-current]:hover, .main-nav__link[aria-current]:focus, .main-nav__link[aria-current]:active {
  background-color: #d94200;
  cursor: default;
  pointer-events: none;
}

@keyframes swipeview-reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes swipeview-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.swipeview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30999;
  background: rgba(56, 52, 51, 0.85);
  backdrop-filter: blur(5px);
  scroll-behavior: auto;
  opacity: 0;
}
.swipeview:not([data-is-open]) {
  visibility: hidden;
}
.swipeview[data-is-open=true] {
  display: block;
  animation: swipeview-reveal 0.25s ease-out forwards;
}
.swipeview[data-is-open=false] {
  animation: swipeview-hide 0.25s ease-out forwards;
}
.swipeview__list-wrapper {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  scroll-behavior: auto;
}
.swipeview__list {
  touch-action: manipulation;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  --edge-transition: none;
  --edge-transform: translateX(0%);
}
.swipeview__item {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: var(--edge-transition);
  transform: var(--edge-transform);
}
.swipeview__image {
  user-select: none;
  pointer-events: none;
  object-fit: contain;
  max-height: 100%;
  background: transparent url(/assets/images/swipeview/loading.svg) no-repeat center center/100px auto !important;
}
.swipeview__button {
  position: fixed;
  box-shadow: none;
  outline: 0;
  z-index: 2;
  width: 50px;
  height: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border-radius: 0;
  transition: background-color 0.18s ease-out;
  background-color: rgba(56, 52, 51, 0.75);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: none;
  background-size: 65%;
}
.swipeview__button:hover, .swipeview__button:focus {
  outline: 0;
  background-color: #26348b;
}
@media screen and (min-width: 800px) {
  .swipeview__button {
    width: 70px;
    height: 70px;
  }
}
.swipeview__button[disabled] {
  visibility: hidden;
}
.swipeview__button--exit {
  right: 0;
  top: 0;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='%23f6f2ef' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E");
}
.swipeview__button--previous, .swipeview__button--next {
  transform: translateY(-50%);
}
.swipeview__button--previous {
  left: 0;
  top: 50%;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='%23f6f2ef' d='M8.502 20c-.003-.259.092-.519.289-.716l19.008-19.007c.389-.389 1.02-.389 1.408 0s.389 1.019 0 1.407l-18.315 18.316 18.315 18.315c.389.389.389 1.019 0 1.407s-1.02.389-1.408 0l-19.008-19.006c-.197-.197-.292-.457-.289-.716z'/%3E%3C/svg%3E");
}
.swipeview__button--next {
  right: 0;
  top: 50%;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='%23f6f2ef' d='M31.209 20.716l-19.008 19.007c-.389.389-1.019.389-1.407 0s-.389-1.019 0-1.407l18.314-18.316-18.315-18.315c-.389-.389-.389-1.019 0-1.407s1.019-.389 1.407 0l19.008 19.007c.197.197.292.457.289.716.004.258-.091.518-.288.715z'/%3E%3C/svg%3E");
}

.pagination {
  display: inline-flex;
  margin: initial auto;
}
.pagination__list {
  padding: 0;
  margin: 1em 0;
  list-style-type: none;
  text-align: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}
.gallery__item {
  display: flex;
  flex-direction: column;
  position: relative;
}
.gallery__link {
  line-height: 0;
  display: block;
}
.gallery__image {
  width: 100%;
  border-radius: 8px;
}

.galleries-sub__list {
  display: grid;
  grid-gap: 24px;
  gap: 24px;
}
@media screen and (min-width: 600px) {
  .galleries-sub__list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1024px) {
  .galleries-sub__list {
    grid-gap: 32px;
    gap: 32px;
  }
}
@media screen and (min-width: 1200px) {
  .galleries-sub__list {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 48px;
    gap: 48px;
  }
}
.galleries-sub__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 30px 0 0 0;
}
.galleries-sub__link, .galleries-sub__link:link, .galleries-sub__link:visited {
  font-family: "Exo 2", Poppins, Futura, Montserrat, sans-serif;
  font-size: 1.3125rem;
  font-weight: bold;
  text-transform: underline;
  text-align: center;
  color: #26348b;
}
.galleries-sub__link:hover, .galleries-sub__link:focus, .galleries-sub__link:active {
  text-decoration: none;
  color: #000839;
}
.galleries-sub__link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.galleries-sub__image-wrapper {
  display: block;
  width: 100%;
  padding-bottom: 67.2514619883%;
  position: relative;
  margin-bottom: 18px;
  background: #e2e9f2 url(/assets/images/wave-main.svg) no-repeat 50% 50%/66px auto;
}
.galleries-sub__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.jobs__list {
  display: grid;
  grid-gap: 24px;
  gap: 24px;
}
@media screen and (min-width: 600px) {
  .jobs__list {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1024px) {
  .jobs__list {
    grid-gap: 32px;
    gap: 32px;
  }
}
@media screen and (min-width: 1200px) {
  .jobs__list {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 48px;
    gap: 48px;
  }
}
.jobs__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 30px 0 0 0;
}
.jobs__link, .jobs__link:link, .jobs__link:visited {
  font-family: "Exo 2", Poppins, Futura, Montserrat, sans-serif;
  font-size: 1.3125rem;
  font-weight: bold;
  text-transform: underline;
  text-align: center;
  color: #26348b;
}
.jobs__link:hover, .jobs__link:focus, .jobs__link:active {
  text-decoration: none;
  color: #000839;
}
.jobs__link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.jobs__date {
  font-weight: bold;
}
.jobs__image-wrapper {
  display: block;
  width: 100%;
  padding-bottom: 67.2514619883%;
  position: relative;
  margin-bottom: 18px;
  background: #e2e9f2 url(/assets/images/wave-main.svg) no-repeat 50% 50%/66px auto;
}
.jobs__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.jobs__category {
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translate(-50%, -50%);
  max-width: 90%;
  padding: 10px 18px;
  border-radius: 9px;
  background-color: #000839;
  color: white;
  font-weight: bold;
  font-size: 0.8125rem;
  margin: 0;
  text-align: center;
}

.languages {
  border-radius: 200px;
  background: #000839 none no-repeat 13px 50%/22px auto;
  color: white;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 0.75rem;
  font-weight: 600;
  min-width: 140px;
  height: 50px;
  padding: 10px 10px 10px 46px;
  border: 0;
}
@media screen and (min-width: 1040px) {
  .languages {
    border-radius: 200px 0 0 200px;
  }
}
.languages.language-cs {
  background-image: url(/assets/images/flag-cs.svg);
}

.fields {
  padding: 100px 0;
}
.fields__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}
@media screen and (min-width: 800px) {
  .fields__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1400px) {
  .fields__list {
    grid-template-columns: repeat(6, 1fr);
  }
}
.page .fields__list {
  grid-gap: 15px;
  gap: 15px;
}
.fields__item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}
.page .fields__item {
  background-color: #dfe9f5;
  padding: 60px 20px 40px;
}
.fields__link {
  font-size: 1.3125rem;
  font-family: "Exo 2", Poppins, Futura, Montserrat, sans-serif;
  font-weight: bold;
  line-height: 1.3333333333;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fields__link, .fields__link:link, .fields__link:visited {
  color: #26348b;
  text-decoration: none;
}
.fields__link:hover, .fields__link:focus, .fields__link:active {
  color: #000839;
}
.fields__link:hover svg .accent, .fields__link:focus svg .accent, .fields__link:active svg .accent {
  fill: #d94200;
}
.fields__icon {
  margin: 0 0 10px;
}
.fields__icon svg {
  width: 150px;
  max-width: 100%;
  height: auto;
}
@media screen and (min-width: 1600px) {
  .fields__icon svg {
    width: 193px;
  }
}

.additional {
  background-color: #26348b;
  color: white;
  padding: 100px 0;
}
.additional__content {
  display: grid;
  grid-gap: 40px;
  gap: 40px;
}
@media screen and (min-width: 800px) {
  .additional__content {
    grid-template-columns: 3fr 4fr 5fr auto;
  }
}
@media screen and (min-width: 1200px) {
  .additional__content {
    grid-gap: 100px;
    gap: 100px;
  }
}
.additional .pages__heading {
  font-size: 1.625rem;
}
.additional .pages__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.additional .pages__item {
  margin: 0 0 12px;
}
.additional .pages__link, .additional .pages__link:link, .additional .pages__link:visited {
  color: inherit;
  text-decoration: underline;
}
.additional .pages__link:hover, .additional .pages__link:focus, .additional .pages__link:active {
  text-decoration: none;
}

.address__logo {
  margin: 0 0 20px;
}
.address__group {
  margin-bottom: 18px;
  padding-left: 54px;
  background: transparent none no-repeat 0 50%;
  line-height: 2.75;
}
.address__group--location {
  background-image: url(/assets/images/icon-location.svg);
}
.address__group--phone {
  background-image: url(/assets/images/icon-phone.svg);
}
.address__group--email {
  background-image: url(/assets/images/icon-mail.svg);
}
.address__line {
  margin: 0;
  padding: 4px 0;
  line-height: normal;
}
.address__link, .address__link:link, .address__link:visited {
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: uppercase;
  text-decoration: underline;
}
.address__link:hover, .address__link:focus, .address__link:active {
  text-decoration: none;
}