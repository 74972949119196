.gallery {
	&__item {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	&__link {
		line-height: 0;
		display: block;
	}

	&__image {
		width: 100%;
		border-radius: 8px;
	}
}


.galleries-sub {
	&__list {
		display: grid;
		grid-gap: 24px;
		gap: 24px;

		@include min(600px) {
			grid-template-columns: 1fr 1fr;
		}

		@include min(1024px) {
			grid-gap: 32px;
			gap: 32px;
		}

		@include min(1200px) {
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 48px;
			gap: 48px;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		padding: 30px 0 0 0;
	}

	&__link {
		@include link(&) {
			font-family: $font-headings;
			font-size: rem(21px);
			font-weight: bold;
			text-transform: underline;
			text-align: center;
			color: $color-brand;
		}

		@include link-over(&) {
			text-decoration: none;
			color: $color-brand-darkest;
		}

		&::before {
			content: "";
			@include fill;
		}
	}

	&__image-wrapper {
		display: block;
		width: 100%;
		padding-bottom: ratio(342, 230);
		position: relative;
		margin-bottom: 18px;
		background: #e2e9f2 url(/assets/images/wave-main.svg) no-repeat 50% 50% / 66px auto;
	}

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center
	}
}
