@use "sass:math";

.header {
	color: #ffffff;
	position: relative;
	padding-top: 35px;
	padding-bottom: min(180px, 18%);
	background-color: $color-brand-darker;
	background-repeat: no-repeat, no-repeat;
	background-position: 50% 100%, 50% 0;
	background-size: auto, cover;
	background-image: url("/assets/images/arc.svg"), url("/assets/images/hero.jpg");
	background-image: url("/assets/images/arc.svg"), image-set(
		url("/assets/images/hero.avif") type("image/avif"),
		url("/assets/images/hero.jpg") type("image/jpeg"));

	@include min(1024px) {
		padding-top: 60px;
	}

	@include min(1400px) {
		padding-top: 90px;
	}

	body:not([data-view="home"]) & {
		padding-bottom: min(160px, 10%);
	}

	&__top {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include min(1040px) {
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
		}
	}

	&__logo {
		margin: 0 0 30px;
		display: flex;
		justify-content: center;
		flex-shrink: 0;

		img {
			width: 300px;

			@include min(600px) {
				width: 400px;
			}

			@include min(1040px) {
				width: 250px;
			}

			@include min(1200px) {
				width: 400px;
			}

			@include min(1500px) {
				width: 495px;
			}
		}
	}

	&__tools {
		margin-top: 30px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 12px;
		gap: 12px;

		@include minmax(800px, 1039px) {
			display: flex;
			justify-content: center;
		}

		@include min(1040px) {
			display: grid;
			grid-template-columns: initial;
			grid-gap: 20px;
			gap: 20px;
			margin-top: 0;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__cta {
		max-width: 780px;
		padding-top: 30px;

		@include min(1400px) {
			padding-top: 90px;
		}

		.cta__heading {
			font-weight: bold;
			font-size: rem(30px);
			font-family: $font-headings;
			line-height: normal;
			margin: 0 0 34px;

			@include min(800px) {
				font-size: rem(40px);
			}

			@include min(1200px) {
				font-size: rem(50px);
			}
		}

		.cta__text {
			font-size: rem(18px);
			line-height: math.div(34, 20);
			margin-bottom: 32px;

			@include min(1200px) {
				font-size: rem(20px);
			}
		}
	}

	&__move-button {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		justify-content: center;

		&::before,
		&::after {
			content: "";
			display: inline-block;
		}

		&::before {
			width: 30px;
			height: 52px;
			border-radius: 52px;
			background-color: $color-secondary;
			box-shadow: 0 0 32px rgba(black, .41);
		}

		&::after {
			width: 2px;
			height: 7px;
			border-radius: 100px;
			background-color: white;
			position: absolute;
			top: 13px;
			left: 50%;
			margin-left: -1px;
		}

		@include link(&) {
			text-decoration: none;
		}

		@include link-over(&) {
			text-decoration: underline;
		}

		span {
			text-transform: lowercase;
			position: absolute;
			left: 50%;
			top: -18px;
			transform: translate(-50%, -100%);
			font-size: rem(12px);
			font-weight: 600;
			white-space: nowrap;
		}
	}
}

.search-button {
	border-radius: 200px;
	background: $color-brand-darkest url(/assets/images/magnifying-glass.svg) no-repeat 15px 50% / 22px auto;
	color: white;
	font-size: rem(12px);
	font-weight: 600;
	min-width: 140px;
	height: 50px;
	padding: 10px 10px 10px 46px;
	display: inline-flex;
	align-items: center;

	@include min(1040px) {
		border-radius: 200px 0 0 200px;
	}

	@include link(&) {
		color: white;
		text-decoration: none;
	}

	@include link-over(&) {
		text-decoration: underline;
	}
}
