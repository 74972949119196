.main-nav {
	padding: 0;

	&__heading {
		margin-top: 0.5em;
	}

	&__list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 12px;
		gap: 12px;
		padding: 0;
		margin: 0;
		list-style-type: none;

		@include min(800px) {
			grid-gap: 0;
			gap: 0;
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
		}
	}

	&__item {
		text-align: center;
		font-weight: 700;
		display: inline-flex;
		font-size: 1rem;
		margin: 0;


		@include min(800px) {
			margin: 0 0 0 12px;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	&__link {
		padding: 13px 20px;
		min-width: 10rem;
		background-color: $color-brand-darkest;
		transition: background-color .15s ease-out;
		border-radius: 300px;

		@include link(&) {
			text-decoration: none;
			color: #fff;
		}

		@include link-over(&) {
			background-color: $color-secondary;
		}

		&[aria-current] {
			@include link-all(&) {
				background-color: $color-secondary;
				cursor: default;
				pointer-events: none;
			}
		}
	}
}
