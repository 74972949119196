.footer {
	text-align: center;
	margin-top: auto;
	padding-top: 52px;
	padding-bottom: 52px;
	font-size: rem(14px);
	position: relative;
	background-color: $color-brand;
	border-top: 1px #303e90 solid;
	color: white;

	&__copyright {
		@include min(800px) {
			margin: 0;
		}
	}

	&__content {
		@include min(800px) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	@include link {
		color: inherit;
		text-decoration: underline;
	}

	&__login-link {
		position: absolute;
		bottom: 0.5rem;
		left: 0.5rem;
		font-size: 1rem;
		opacity: 0;

		@include link(&) {
			color: inherit;
			text-decoration: none;
		}

		@include link-over(&) {
			opacity: 1;
		}
	}
}
